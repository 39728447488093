import React from 'react';
import { MDBBtn, MDBInput, MDBRow, MDBCol, MDBBadge, MDBNavLink } from 'mdbreact';
import DataLayer from '../Utilities/DataLayer';

export default class EscalateTicket extends React.Component {

    client = {
        id: "",
        name: "",
        surname: "",
        cell: "",
        gender: "",
        dateOfBirth: undefined,
        saCitizen: true,
    }

    ticket = {
        hostRef: "",
        agentId: "",
        clientId: "",
        storeId: "",
        storeAgent: "",
        status: "",
        opened: "",
        closed: "",
        reason: "",
        resolution: "",
        escalatedId: "",
        notes: []
    }

    constructor(props) {
        super();

        this.state = {
            client: this.client,
            ticket: this.ticket,
            ticketCount: 0,
            getNextTicket: false,
            tempReason: '',
            tempNotes: ''
        }

        this.dataLayer = new DataLayer();
        this.onSubmit = this.onSubmit.bind(this);
        this.updateTicket = this.updateTicket.bind(this);
    }

    componentWillMount() {
        this.loadTicket();
    }

    async loadTicket() {
        this.props.loaderOn();
        let allOpenTickets = await this.dataLayer.Get('/getopentickets');

        if (allOpenTickets == null) {
            this.props.notify('success', 'No tickets available');
            const { history } = this.props;
            history.push('/workspace');
            return;
        } else {
            this.setState({ getNextTicket: true });
        }

        this.setState({
            ticketCount: allOpenTickets.length
        });

        if (this.props.location.state != null) {
            try {
                this.ticket = allOpenTickets.filter((ticket) => ticket._id === this.props.location.state.ticket_id)[0];
            } catch (e) {
                this.ticket.clientId = "";
            }
        }

        if (this.ticket == null || this.ticket.clientId == null || this.ticket.clientId === "") {
            this.ticket = allOpenTickets.filter((ticket) => ticket.escalatedId === sessionStorage.getItem('foneBookUser'));

            if (this.ticket.clientId == undefined) {
                this.ticket = allOpenTickets[0];
            }
            else {
                this.ticket = this.ticket[0];
            }
        }

        if (this.ticket.notes === undefined) {
            this.ticket.notes = [];
        }

        if (this.ticket != null) {
            this.setState({ getNextTicket: false });
        }

        if (this.state.getNextTicket) {
            this.ticket = allOpenTickets[0];
            this.setState({ getNextTicket: false });
        }

        if (this.ticket.notes === undefined) {
            this.ticket.notes = [];
        }

        this.client = await this.dataLayer.Get(`/getclientdetails/${this.ticket.clientId}`);
        this.client.dateOfBirth = this.client.dateOfBirth.substring(0, 10);

        this.setState({
            client: this.client,
            ticket: this.ticket,
            tempReason: '',
            tempNotes: ''
        });
        this.props.loaderOff();
    }

    async updateTicket(){
        this.props.loaderOn();
        this.ticket.resolution = this.state.tempReason;
        this.ticket.notes.push(`[${sessionStorage.getItem('foneBookUser')}] ${this.state.tempNotes}`);
        await this.dataLayer.Post('/updateticket', this.ticket);
        this.props.loaderOff();
        this.props.history.push('/workspace');
    }

    async onSubmit(event) {
        event.preventDefault();
        this.props.loaderOn();
        this.ticket.resolution = event.target.resolution.value;
        this.ticket.notes.push(`[${sessionStorage.getItem('foneBookUser')}] ${event.target.note.value}`);
        this.ticket.closed = new Date();
        this.ticket.status = 'closed';
        await this.dataLayer.Post('/updateticket', this.ticket);
        document.getElementById('resetButton').click();
        this.props.loaderOff();
        this.loadTicket();
    }

    render() {

        if (this.ticket.notes == undefined) {
            this.ticket.notes = [];
        }

        return (
            <div>
                <MDBRow between>
                    <MDBCol>
                        <h3 className="mb-3">Escalated Ticket</h3>
                    </MDBCol>
                    <MDBCol className="text-right">
                        <h4>
                            <MDBNavLink to="/workspace">
                                <MDBBadge color="info">{this.state.ticketCount} Open</MDBBadge>
                            </MDBNavLink>
                        </h4>
                    </MDBCol>
                </MDBRow>


                {/* STORE AGENTS */}
                <MDBRow>
                    <MDBCol md="6" sm="12">
                        <MDBInput
                            label="Store"
                            type="text"
                            value={this.ticket.storeId}
                            disabled
                        />
                    </MDBCol>
                    <MDBCol md="6" sm="12">
                        <MDBInput
                            label="Store Agent"
                            type="text"
                            value={this.ticket.storeAgent}
                            disabled
                        />
                    </MDBCol>
                    {/* CUSTOMER INFORMATION */}
                    <MDBCol md="6" sm="12">
                        <MDBInput
                            name="hostRef"
                            label="Host System Reference"
                            type="text"
                            value={this.ticket.hostRef}
                            disabled
                        />
                    </MDBCol>

                    <MDBCol md="6" sm="12">
                        <MDBInput
                            name="id"
                            label="Customer ID / Passport Number"
                            type="text"
                            value={this.client.id}
                            disabled
                        />
                    </MDBCol>

                    <MDBCol md="6" sm="12">
                        <MDBInput
                            name="name"
                            label="First Name"
                            type="text"
                            value={this.client.name}
                            disabled
                        />
                    </MDBCol>
                    <MDBCol md="6" sm="12">
                        <MDBInput
                            name="surname"
                            label="Surname"
                            type="text"
                            value={this.client.surname}
                            disabled
                        />
                    </MDBCol>
                    <MDBCol md="6" sm="12">
                        <MDBInput
                            name="dateOfBirth"
                            label="Date of Birth"
                            type="date"
                            value={this.client.dateOfBirth}
                            disabled
                        />
                    </MDBCol>
                    <MDBCol md="6" sm="12">
                        <MDBInput
                            name="gender"
                            label="Gender"
                            type="text"
                            value={this.client.gender}
                            disabled
                        />
                    </MDBCol>
                    <MDBCol md="6" sm="12">
                        <MDBInput
                            name="cell"
                            label="Cellphone Number"
                            type="text"
                            value={this.client.cell}
                            disabled
                        />
                    </MDBCol>
                    <MDBCol md="6" sm="12">
                        <MDBInput
                            name="open"
                            label="Date Opened"
                            type="text"
                            value={this.ticket.opened}
                            disabled
                        />
                    </MDBCol>
                    <MDBCol md="6" sm="12">
                        <MDBInput
                            name="reason"
                            label="Ticket Reason"
                            type="text"
                            value={this.ticket.reason}
                            disabled
                        />
                    </MDBCol>
                    <MDBCol md="6" sm="12">
                        <input
                            name="saCitizen"
                            label="SA Citizen"
                            type="checkbox"
                            checked={this.client.saCitizen}
                            disabled
                        /> SA Citizen
                        </MDBCol>

                    <MDBCol md="12" sm="12">
                        <h5>Notes</h5>
                        {
                            this.ticket.notes.map((note) => (<p><i>{note}</i></p>))
                        }
                    </MDBCol>
                </MDBRow>

                <form id="ResolutionForm" onSubmit={this.onSubmit}>
                    <MDBInput
                        name="resolution"
                        label="Resolution"
                        type="text"
                        value={this.state.tempReason}
                        onChange={(event) => {
                            this.setState({ tempReason: event.target.value });
                        }}
                        required
                    />
                    <MDBInput
                        label="Note"
                        type="textarea"
                        name="note"
                        value={this.state.tempNotes}
                        onChange={(event) => {
                            this.setState({ tempNotes: event.target.value });
                        }}
                        required
                    />

                    <MDBBtn color="mdb-color" type="submit" id="close">Close Ticket</MDBBtn>
                    <MDBBtn color="mdb-color" onClick={this.updateTicket}>Update Ticket</MDBBtn>
                    <MDBBtn id="resetButton" color="mdb-color" type="reset" hidden></MDBBtn>
                </form>
            </div>
        );
    }
}
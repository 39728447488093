import React from 'react';
import { MDBDataTable, MDBBtn } from 'mdbreact';
import DataLayer from '../Utilities/DataLayer';
import Security from '../Utilities/Security';

export default class Dashboard extends React.Component {

    constructor(props) {
        super();

        this.state = {
            columns: [
                {
                    label: 'Escalated To',
                    field: 'escalatedId',
                    sort: 'asc'
                },
                {
                    label: 'Client ID Number',
                    field: 'clientId',
                    sort: 'asc'
                },
                {
                    label: 'Host Reference Number',
                    field: 'hostRef',
                    sort: 'asc'
                },
                {
                    label: 'Opened On',
                    field: 'opened',
                    sort: 'asc'
                },
                {
                    label: 'Reason',
                    field: 'reason',
                    sort: 'asc'
                },
                {
                    label: 'Open',
                    sort: 'asc'
                }
            ],
            rows: []
        }

        this.security = new Security();
        this.dataLayer = new DataLayer();
        this.loadOpenTickets = this.loadOpenTickets.bind(this);
        this.openTicket = this.openTicket.bind(this);
        this.processChangeEvent = this.processChangeEvent.bind(this);
    }

    componentWillMount() {
        this.loadOpenTickets();
    }

    async loadOpenTickets() {
        this.props.loaderOn();
        let openTickets = await this.dataLayer.Get('/getopentickets');
        let rows = [];

        if (openTickets != null) {
            openTickets.forEach((ticket, index) => {
                let row = {
                    escalatedId: ticket.escalatedId,
                    clientId: ticket.clientId,
                    hostRef: ticket.hostRef,
                    opened: ticket.opened,
                    reason: ticket.reason,
                    id: <MDBBtn color="mdb-color" name={ticket._id} size="sm" onClick={this.openTicket}>Open</MDBBtn>
                }
                rows.push(row);
            });
        }
        this.props.loaderOff();
        this.setState({ rows: rows });
    }

    openTicket(event) {
        let ticket_id = event.target.name;
        const { history } = this.props;
        history.push('/workspace/escalatedticket', { ticket_id: ticket_id });
    }

    processChangeEvent() {
        this.setState({ refresh: true });
    }

    render() {
        let gridData = { columns: this.state.columns, rows: this.state.rows }
        return (
            <div>
                <h4>Open Tickets</h4>
                <MDBDataTable
                    striped
                    bordered
                    small
                    data={gridData}
                />
            </div>
        );
    }
}
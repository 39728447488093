import axios from 'axios';
import AppSettings from '../appsettings';
import Security from './Security';

export default class DataLayer {

    security = new Security();

    async Get(path) {
        this.security.validateSession();
        try {
            let response = await axios.get(`${AppSettings.serverEndpoint}${path}`, this.setHeaders());
            return response.data;
        } catch (exception) {
            if (exception.response.status === 500) {
                alert(`An error occurred. ${exception.response.data.message}`);
            }
            else {
                alert(`An error occurred. ${exception}`);
            }
        }
    }

    async Post(path, object) {
        this.security.validateSession();
        try {
            let response = await axios.post(`${AppSettings.serverEndpoint}${path}`, JSON.stringify(object), this.setHeaders());
            return response.data;
        } catch (exception) {
            if (exception.response.status === 500) {
                alert(`An error occurred. ${exception.response.data.message}`);
            }
            else {
                alert(`An error occurred. ${exception}`);
            }
        }
    }

    setHeaders() {
        const https = require('https');
        return {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            , httpsAgent: new https.Agent({ rejectUnauthorized: false })
        }
    }
}